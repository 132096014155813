<template>
  <div>
    <Toast/>
    <DataTable :value="tickets" dataKey="path" :loading="loading" :paginator="true" :rows="20"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[20,50,100]"
               :currentPageReportTemplate="$t('Showing {first} to {last} of {totalRecords} entries')">
      <template #loading>
        Loading customers data. Please wait.
      </template>
      <Column headerStyle="width: 3em">
        <template #header>
          <Button icon="pi pi-plus" class="p-button-rounded" @click="displayInvitationForm = true"/>
        </template>
        <template #body="slotProps">
          <div class="p-d-flex p-ai-center p-jc-center">
            <Button icon="pi pi-search" class="p-button-rounded" @click="showTicket(slotProps.data)"/>
          </div>
        </template>
      </Column>
      <Column headerStyle="width: 6em" field="ticket.hid" header="#" :sortable="true">
        <template #body="slotProps">
          <div class="p-d-flex p-ai-center p-jc-center">
            {{ slotProps.data.ticket.hid }}
          </div>
        </template>
        <template #filter>
          <InputText type="text" v-model="filters['ticket.hid']" class="p-column-filter" @input="filterTickets()"/>
        </template>
      </Column>
      <Column headerStyle="width: 12em" field="ticket.created" :header="$t('Created')" :sortable="true">
        <template #body="slotProps">
          <div class="p-d-flex p-jc-center p-ai-center">
            {{ new Date(slotProps.data.ticket.created).toLocaleString() }}
          </div>
        </template>
      </Column>
      <Column header="Email" :sortable="true">
        <template #body="slotProps">
          <div class="p-d-flex p-jc-center p-ai-center">
            <div class="o-pill p-d-flex p-ai-center p-jc-center p-p-2" @click="goToUser(slotProps.data.ticket.user)">
              {{ slotProps.data.ticket.user }} <i class="pi pi-external-link p-ml-2"/>
            </div>
          </div>
        </template>
        <template #filter>
          <InputText type="text" v-model="filters['ticket.user']" class="p-column-filter" @input="filterTickets()"/>
        </template>
      </Column>
      <Column field="ticket.assignee" :header="$t('Assignee')" :sortable="true">
        <template #body="slotProps">
          <div class="p-d-flex p-ai-center p-jc-center">
            {{ slotProps.data.ticket.assignee || $t('Unassigned') }}
          </div>
        </template>
        <template #filter>
          <InputText type="text" v-model="filters['ticket.assignee']" class="p-column-filter" @input="filterTickets()"/>
        </template>
      </Column>
      <Column headerStyle="width: 12em" field="ticket.lastActivity" :header="$t('Last Activity')" :sortable="true">
        <template #body="slotProps">
          <div class="p-d-flex p-ai-center p-jc-center">
            {{
              slotProps.data.ticket.lastActivity ? new Date(slotProps.data.ticket.lastActivity).toLocaleString() : 'N/A'
            }}
          </div>
        </template>
      </Column>
      <Column headerStyle="width: 4em" field="ticket.active" :header="$t('Open')" :sortable="true">
        <template #body="slotProps">
          <div class="p-d-flex p-ai-center p-jc-center">
            <i v-if="slotProps.data.ticket.active" class="pi pi-circle-on"/>
            <i v-else class="pi pi-circle-off"/>
          </div>
        </template>
        <template #filter>
          <div class="p-d-flex p-jc-center p-ai-center">
            <Checkbox v-model="filters['ticket.active']" :binary="true" @change="filterTickets()"/>
          </div>
        </template>
      </Column>
    </DataTable>
    <Dialog :header="$t('New Invitation')" :visible.sync="displayInvitationForm" :style="{width: '600px'}" :modal="true"
            :closable="false">
      <FormViewer :style="{'min-height': '250px'}" :showSave="false" :form="inviteForm"
                  @form-valid="setIsInvitationValid"/>
      <Dialog :header="$t('Confirmation')" :visible.sync="alreadyExistPrompt" :style="{width: '500px'}" :modal="true"
              :closable="false">
        <div class="p-invalid">{{ $t(this.invitationMessage) }}</div>
        <template #footer>
          <div class="p-mt-4">
            <Button class="p-mr-2" :label="$t('Send')" :disabled="!invitationValid" @click="inviteUser()"/>
            <Button class="p-mr-2 p-button-danger" :label="$t('No')" @click="() => {alreadyExistPrompt = false; loading = false;}"/>
          </div>
        </template>
      </Dialog>
      <Message v-if="invitationError" severity="error">{{ invitationError }}</Message>

      <template #footer>
        <div class="p-mt-4">
          <Button :icon="loading ? 'pi pi-spin pi-spinner' : ''" class="p-mr-2" :label="$t('Send')" :disabled="!invitationValid && !loading" @click="checkInviteUser()"/>
          <Button class="p-mr-2 p-button-danger" :label="$t('Cancel')" @click="displayInvitationForm = false"/>
        </div>
      </template>
    </Dialog>

    <Dialog :header="$t('Notice')" :visible.sync="displayGenericDialog" :dismissableMask="true"
            :style="{width: '600px'}" :modal="true" :closable="false">
      {{ $t(genericDialogText) }}
      <template #footer>
        <div class="p-mt-4">
          <Button class="p-mr-2" :label="$t('Ok')" @click="displayGenericDialog = false"/>
        </div>
      </template>
    </Dialog>
    <div v-if="currentTicket">
      <Dialog :header="currentTicket.ticket.hid" :visible.sync="displayModal" :style="{width: '80vw'}" :modal="true"
              :closable="true" @show="resolution = null">
        <Toolbar>
          <template #left>
            <Button :label="$t('{user}s account',{user:currentTicket.ticket.user})" icon="pi pi-external-link"
                    @click="goToUser(currentTicket.ticket.user)"/>
            <i class="pi pi-bars p-toolbar-separator p-mr-2"/>
            <Dropdown class="p-d-inlineflex p-ai-center p-mr-2 p-p-2"
                      style="height: 37px; text-align: left; width: 300px;" v-model="currentTicket.ticket.assignee"
                      :options="adminUsersList" optionLabel="label" optionValue="value"
                      :placeholder="$t('Select Assignee')" :showClear="true"/>
          </template>
        </Toolbar>
        <Panel class="p-mt-5" :header="$t('Invitation form')">
          <FormViewer :showSave="false" :form="sortForm" :view-only="true"/>
        </Panel>
        <HistoryViewer :history="currentTicket.ticket.history" @save="saveHistory()" class="p-mt-5"/>
        <template #footer>
          <div v-if="currentTicket.ticket.active" class="p-mt-4">
            <Dropdown class="p-d-inlineflex p-ai-center p-mr-2 p-p-2"
                      style="height: 37px; text-align: left; width: 300px;" v-model="resolution" :options="resolutions"
                      :placeholder="$t('Select a resolution to close this ticket')"/>
            <Button class="p-mr-2" :label="$t('Close ticket')" :disabled="!resolution" @click="closeTicket()"/>
            <Button class="p-mr-2 p-button-danger" :label="$t('Cancel')" @click="displayModal = false"/>
          </div>
          <div v-else class="p-mt-4">
            <Button class="p-mr-2 p-button-info" :label="$t('Reopen ticket')" @click="reopenTicket()"/>
          </div>

        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import ApiService from '../service/ApiService';
import FormViewer from '../components/FormViewer.vue';
import HistoryViewer from '../components/HistoryViewer.vue';


export default {
  name: "Invitations",
  props: {},
  data() {
    return {
      api: null,
      tickets: [],
      allTickets: [],
      filters: {
        "ticket.active": true
      },
      loading: true,
      adminUsersList: [],
      displayGenericDialog: false,
      genericDialogText: null,
      displayModal: false,
      displayInvitationForm: false,
      invitationValid: false,
      invitationMessage: "",
      invitationError: "",
      alreadyExistPrompt: false,
      currentTicket: null,
      tempForm: {},
      sortForm: {},
      resolution: null,
      resolutions: [
        'completed',
        'canceled',
        'rejected'
      ],
      inviteForm: {
        aa_instruction: {
          type: "instructions",
          value: this.$t("Select the desired invitation template and set the email address of the user to send the invitation to.")
        },
        ab_invitationtype: {
          options: [],
          type: 'dropdown',
          label: this.$t('Template'),
          optionLabel: "ticket.name",
          invalidMessage: 'An invitation template is required',
          mandatory: true
        },
        ac_email: {
          type: 'text',
          label: this.$t('Email'),
          mandatory: true,
          invalidMessage: 'A valid email address is required',
          validate: (email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          }
        }
      }
    };
  },
  created() {
    this.api = new ApiService();
    this.reloadTickets();
    this.api.getFromEp('tickets', {
      filters: JSON.stringify([{field: 'type', value: 'template'}, {
        field: "template",
        value: "platform_access_request"
      }])
    }).then((templates) => {
      this.inviteForm.ab_invitationtype.options = templates;
    });
    this.getUserAdmins();
  },
  methods: {
    setIsInvitationValid(isValid) {
      this.invitationValid = isValid;
    },
    getUserAdmins() {
      this.api.getFromKc(`clients/`).then((clients) => {
        let realmManagementId = clients.filter(c => c.clientId === 'realm-management')[0]?.id;
        this.api.getFromKc(`clients/${realmManagementId}/roles/manage-users/users`).then((users) => {
          this.adminUsersList = users.map((u, i) => {
            return {index: i + 2, value: u.email, label: u.email}
          });
          this.adminUsersList.some((u, i) => {
            if (u.email === this.$auth.user.email) {
              this.adminUsersList[i].index = 1;
              return true;
            }
          })
          this.adminUsersList = this.adminUsersList.sort((a, b) => a.index - b.index);
        });
      });
    },
    filterTickets() {
      this.tickets = this.allTickets
          .filter(t => {
            if (this.filters['ticket.hid']) {
              return t.ticket.hid.includes(this.filters['ticket.hid']);
            }
            return true;
          })
          .filter(t => {
            if (this.filters['ticket.user']) {
              return t.ticket.user.includes(this.filters['ticket.user']);
            }
            return true;
          })
          .filter(t => {
            if (this.filters['ticket.assignee']) {
              if (t.ticket.assignee) {
                return t.ticket.assignee.includes(this.filters['ticket.assignee']);
              }
              return false;
            }
            return true;
          })
          .filter(t => {
            if (this.filters['ticket.active']) {
              return t.ticket.active;
            }
            return true;
          })
    },
    setTicketAssignee(email) {
      this.loading = true;
      this.currentTicket.ticket.history.push({
        author: this.$auth.user.email,
        type: "activity",
        icon: "pi-play",
        timestamp: new Date().toISOString(),
        content: email ? "Ticket assigned to {email}" : "Ticket unassigned"
      })
      this.currentTicket.ticket.assignee = email;
      this.saveTicket();
    },
    reloadTickets() {
      this.api.getFromEp('tickets', {
        filters: JSON.stringify([{
          field: 'type',
          value: 'platform_access_request'
        }])
      }).then((tickets) => {
        this.allTickets = tickets;
        this.filterTickets();
        this.loading = false;
      });
    },
    closeTicket() {
      this.loading = true;
      this.currentTicket.ticket.history.push({
        author: this.$auth.user.email,
        type: "activity",
        icon: "pi-play",
        timestamp: new Date().toISOString(),
        content: "Ticket closed by {email}"
      })
      this.currentTicket.ticket.active = false;
      this.currentTicket.ticket.resolution = this.resolution;
      this.saveTicket();

      if (this.resolution === "completed") {
        const user = this.currentTicket.ticket.user;
        let email = {
          to: user,
          from: process.env.VUE_APP_EMAIL_FROM,
          subject: this.$t('V-trace Access Granted'),
          text: this.$t("Your user has been authorized to access the V-trace Platform. \n To use V-trace, please follow this link : \n{link}", {link: location.href.split('#')[0]})
        }

        this.api.postToEp('email', email).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Success'),
            detail: this.$t('Access authorization successfully sent to {email}', {email: user}),
            life: 3000
          });
        });
      }

      this.currentTicket = null;
      this.displayModal = false;

    },
    reopenTicket() {
      this.loading = true;
      this.currentTicket.ticket.history.push({
        author: this.$auth.user.email,
        type: "activity",
        icon: "pi-play",
        timestamp: new Date().toISOString(),
        content: "Ticket re-opened by {email}"
      })
      this.currentTicket.ticket.active = true;
      this.saveTicket();
    },
    goToUser(email) {
      this.api.getFromKc('users', {email}).then((user) => {
        if (Array.isArray(user) && user[0]?.id) {
          window.open(`${process.env.VUE_APP_KEYCLOAK_URL}auth/admin/${process.env.VUE_APP_KEYCLOAK_REALM}/console/#/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/users/${user[0].id}`, '_blank');
        } else {
          this.genericDialogText = 'This user has not yet created his account. Please check the ticket for activity.';
          this.displayGenericDialog = true;
        }
      });
    },
    inviteUser() {
      this.invitationError = '';
      let email = {
        to: this.inviteForm.ac_email.value,
        from: process.env.VUE_APP_EMAIL_FROM,
        subject: this.$t('V-trace Invitation'),
        text: this.$t("Please follow this link to create an account and fill the access form.{link}", {link: location.href.split('#')[0]})
      }
      this.api.postToEp('email', email).then(() => {
        let ticket = this.inviteForm.ab_invitationtype.value.ticket;
        ticket.type = 'platform_access_request';
        ticket.hid = "";
        ticket.history = [
          {
            author: this.$auth.user.email,
            type: "activity",
            icon: "pi-play",
            timestamp: new Date().toISOString(),
            content: "Ticket created by {email}"
          }
        ];
        ticket.created = Date.now();
        ticket.lastActivity = Date.now();
        ticket.user = this.inviteForm.ac_email.value;
        ticket.active = true;
        this.api.postToEp('tickets', {ticket}).then(() => {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Success'),
            detail: this.$t('Invitation sent successfully'),
            life: 3000
          });
          this.alreadyExistPrompt = false;
          this.displayInvitationForm = false;
          this.reloadTickets();
        })
      })
          .catch((e) => {
            this.alreadyExistPrompt = false;
            this.invitationError = this.$t('Error sending inviration message : {errordetails}', {errordetails: e});
          });
    },
    checkInviteUser() {
      this.loading = true;
      this.api.getFromKc('users', {email: this.inviteForm.ac_email.value}).then((users) => {
        if (users.length) {
          this.alreadyExistPrompt = true;
          this.invitationMessage = "User already exists. Send new invitation anyway?";
        } else {
          this.inviteUser();
        }
      });
    },
    showTicket(pl) {
      this.currentTicket = pl;
      this.tempForm = pl.ticket.form;
      this.sortForm = {};
      Object.keys(this.tempForm)
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      })
      .forEach((k) => this.sortForm[k] = this.tempForm[k]);

      this.currentTicket.ticket.history.push({
        type: "activity",
        timestamp: new Date().toISOString(),
        icon: "pi-eye",
        author: this.$auth.user.email,
        content: this.$t("Ticket viewed by {email}", {email: this.$auth.user.email})
      });
      this.saveHistory(true);
      this.displayModal = true;
    },
    saveTicket() {
      this.currentTicket.ticket.lastActivity = Date.now();
      this.api.postToEp('tickets', this.currentTicket).then(() => {
        this.reloadTickets();
        this.$toast.add({
          severity: 'success',
          summary: this.$t('Success'),
          detail: this.$t('Ticket updated successfully'),
          life: 3000
        });
      })
    },
    saveHistory(silent) {
      this.currentTicket.ticket.lastActivity = Date.now();
      this.api.postToEp('tickets', this.currentTicket).then(() => {
        if (!silent) {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('Success'),
            detail: this.$t('Comment added successfully'),
            life: 3000
          });
        }
      })
    }
  },
  components: {
    FormViewer,
    HistoryViewer
  },
};
</script>

<style scoped>
.o-link {
  cursor: pointer;
}

.o-pill {
  background-color: #DEE2E6;
  border-radius: 15px;
  height: 30px;
  width: auto;
  cursor: pointer;
  font-weight: 600;
}

.p-invalid {
  color: #FF0000
}

/deep/ .p-paginator .p-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
